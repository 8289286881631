import i18n from '../../lib/utils/i18n';

export const activityOptions = [
  { label: i18n.t('assetLabels.activityOptions.DELIVERY'), value: 'DELIVERY' },
  { label: i18n.t('assetLabels.activityOptions.SEWAGE'), value: 'SEWAGE' },
  { label: i18n.t('assetLabels.activityOptions.PURIFICATION'), value: 'PURIFICATION' },
];

export const valveConditionOptions = [
  { label: i18n.t('assetLabels.valveConditionOptions.OPEN'), value: 'OPEN' },
  { label: i18n.t('assetLabels.valveConditionOptions.CLOSED'), value: 'CLOSED' },
];

export const valveStsOptions = [
  { label: i18n.t('assetLabels.valveStsOptions.WORKING'), value: 'WORKING' },
  { label: i18n.t('assetLabels.valveStsOptions.NOT_WORKING'), value: 'NOT_WORKING' },
];

export const pumpingStationStsOptions = [
  { label: i18n.t('assetLabels.pumpingStationStsOptions.MAIN'), value: 'MAIN' },
  { label: i18n.t('assetLabels.pumpingStationStsOptions.BACKUP'), value: 'BACKUP' },
];

export const cleaningOptions = [
  { label: i18n.t('assetLabels.cleaningOptions.MECHANICAL'), value: 'MECHANICAL' },
  { label: i18n.t('assetLabels.cleaningOptions.BIOLOGICAL'), value: 'BIOLOGICAL' },
  { label: i18n.t('assetLabels.cleaningOptions.TERTIARY'), value: 'TERTIARY' },
];

export const chlorinationTypeOptions = [
  { label: i18n.t('assetLabels.chlorinationOptions.MANUAL'), value: 'MANUAL' },
  { label: i18n.t('assetLabels.chlorinationOptions.MECHANIC'), value: 'MECHANIC' },
];

export const hasElectricDriveOptions = [
  { label: i18n.t('labAccreditedOptions.True'), value: 'true' },
  { label: i18n.t('labAccreditedOptions.False'), value: 'false' },
];

export const materialOptions = [
  { label: i18n.t('assetLabels.materialOptions.PEHD'), value: 'PEHD' },
  { label: i18n.t('assetLabels.materialOptions.ETERNIT'), value: 'ETERNIT' },
  { label: i18n.t('assetLabels.materialOptions.GALVANIZED'), value: 'GALVANIZED' },
  { label: i18n.t('assetLabels.materialOptions.REINFORCED_CONCRETE'), value: 'REINFORCED_CONCRETE' },
  { label: i18n.t('assetLabels.materialOptions.PVC'), value: 'PVC' },
  { label: i18n.t('assetLabels.materialOptions.CONCRETE'), value: 'CONCRETE' },
  { label: i18n.t('assetLabels.materialOptions.CAST_IRON'), value: 'CAST_IRON' },
  { label: i18n.t('assetLabels.materialOptions.MANESMAN'), value: 'MANESMAN' },
  { label: i18n.t('assetLabels.materialOptions.STEEL'), value: 'STEEL' },
  { label: i18n.t('assetLabels.materialOptions.KAMENIN'), value: 'KAMENIN' },
  { label: i18n.t('assetLabels.materialOptions.MASSIVE'), value: 'MASSIVE' },
  { label: i18n.t('assetLabels.materialOptions.STONE'), value: 'STONE' },
  { label: i18n.t('assetLabels.materialOptions.PE'), value: 'PE' },
  { label: i18n.t('assetLabels.materialOptions.PEH'), value: 'PEH' },
  { label: i18n.t('assetLabels.materialOptions.PEXAO'), value: 'PEXAO' },
  { label: i18n.t('assetLabels.materialOptions.PEVP'), value: 'PEVP' },
  { label: i18n.t('assetLabels.materialOptions.PP'), value: 'PP' },
  { label: i18n.t('assetLabels.materialOptions.FIBERGLASS_PIPES'), value: 'FIBERGLASS_PIPES' },
  { label: i18n.t('assetLabels.materialOptions.GLASS_CERAMIC_PIPES'), value: 'GLASS_CERAMIC_PIPES' },
  { label: i18n.t('assetLabels.materialOptions.HARDENED_IN_PLACE_PIPES'), value: 'HARDENED_IN_PLACE_PIPES' },
];
export const sizeOptions = [
  { label: i18n.t('assetLabels.sizeOptions.LENGTH'), labelValueKey: 'assetLabels.sizeOptions.LENGTHVALUE', value: 'LENGTH' }, // eslint-disable-line max-len
  { label: i18n.t('assetLabels.sizeOptions.VOLUME'), labelValueKey: 'assetLabels.sizeOptions.VOLUMEVALUE', value: 'VOLUME' }, // eslint-disable-line max-len
  { label: i18n.t('assetLabels.sizeOptions.DIAMETER'), labelValueKey: 'assetLabels.sizeOptions.DIAMETERVALUE', value: 'DIAMETER' }, // eslint-disable-line max-len
  { label: i18n.t('assetLabels.sizeOptions.AREA'), labelValueKey: 'assetLabels.sizeOptions.AREAVALUE', value: 'AREA' },
  { label: i18n.t('assetLabels.sizeOptions.POWER'), labelValueKey: 'assetLabels.sizeOptions.POWERVALUE', value: 'POWER' }, // eslint-disable-line max-len
  { label: i18n.t('assetLabels.sizeOptions.FLOW_RATE'), labelValueKey: 'assetLabels.sizeOptions.FLOW_RATEVALUE', value: 'FLOW_RATE' }, // eslint-disable-line max-len
  { label: i18n.t('assetLabels.sizeOptions.CURRENT'), labelValueKey: 'assetLabels.sizeOptions.CURRENTVALUE', value: 'CURRENT' }, // eslint-disable-line max-len
  { label: i18n.t('assetLabels.sizeOptions.INRUSH'), labelValueKey: 'assetLabels.sizeOptions.INRUSHVALUE', value: 'INRUSH' }, // eslint-disable-line max-len
  { label: i18n.t('assetLabels.sizeOptions.CHLORINE_NORM'), labelValueKey: 'assetLabels.sizeOptions.CHLORINE_NORMVALUE', value: 'CHLORINE_NORM' }, // eslint-disable-line max-len
];

export const typeOptions = [
  { label: i18n.t('assetLabels.typeOptions.BUNKER_PUMPING_STATION'), value: 'BUNKER_PUMPING_STATION' },
  { label: i18n.t('assetLabels.typeOptions.CAPTATION'), value: 'CAPTATION' },
  { label: i18n.t('assetLabels.typeOptions.CHLORINE_FARM'), value: 'CHLORINE_FARM' },
  { label: i18n.t('assetLabels.typeOptions.COMMON'), value: 'COMMON' },
  { label: i18n.t('assetLabels.typeOptions.PUBLIC_WATER_TREATMENT_PLANT'), value: 'PUBLIC_WATER_TREATMENT_PLANT' },
  { label: i18n.t('assetLabels.typeOptions.EXTERNAL_SEWER_NETWORK'), value: 'EXTERNAL_SEWER_NETWORK' },
  { label: i18n.t('assetLabels.typeOptions.EXTERNAL_WATER_SUPPLY_NETWORK'), value: 'EXTERNAL_WATER_SUPPLY_NETWORK' },
  { label: i18n.t('assetLabels.typeOptions.FITTINGS_WATER_NETWORK'), value: 'FITTINGS_WATER_NETWORK' },
  { label: i18n.t('assetLabels.typeOptions.INTERNAL_SEWER_NETWORK'), value: 'INTERNAL_SEWER_NETWORK' },
  { label: i18n.t('assetLabels.typeOptions.INTERNAL_WATER_SUPPLY_NETWORK'), value: 'INTERNAL_WATER_SUPPLY_NETWORK' },
  { label: i18n.t('assetLabels.typeOptions.PRESSURE_SEWER_NETWORK'), value: 'PRESSURE_SEWER_NETWORK' },
  { label: i18n.t('assetLabels.typeOptions.PRESSURE_WATER_SUPPLY_NETWORK'), value: 'PRESSURE_WATER_SUPPLY_NETWORK' },
  { label: i18n.t('assetLabels.typeOptions.SEWAGE_PUMPING_STATION'), value: 'SEWAGE_PUMPING_STATION' },
  { label: i18n.t('assetLabels.typeOptions.SEWAGE_TREATMENT_PLANT'), value: 'SEWAGE_TREATMENT_PLANT' },
  { label: i18n.t('assetLabels.typeOptions.TANK'), value: 'TANK' },
  { label: i18n.t('assetLabels.typeOptions.WATER_INTAKE'), value: 'WATER_INTAKE' },
  { label: i18n.t('assetLabels.typeOptions.WELL'), value: 'WELL' },
  { label: i18n.t('assetLabels.typeOptions.MANHOLE'), value: 'MANHOLE' },
  { label: i18n.t('assetLabels.typeOptions.SWITCHING_SHAFT'), value: 'SWITCHING_SHAFT' },
  { label: i18n.t('assetLabels.typeOptions.RELIEF_SHAFT'), value: 'RELIEF_SHAFT' },
  { label: i18n.t('assetLabels.typeOptions.DISTRIBUTION_SHAFT'), value: 'DISTRIBUTION_SHAFT' },
  { label: i18n.t('assetLabels.typeOptions.WATER_METER_SHAFTS'), value: 'WATER_METER_SHAFTS' },
  { label: i18n.t('assetLabels.typeOptions.SANITARY_PROTECTION_ZONE'), value: 'SANITARY_PROTECTION_ZONE' },
  { label: i18n.t('assetLabels.typeOptions.WATER_SUPPLY'), value: 'WATER_SUPPLY' },
  { label: i18n.t('assetLabels.typeOptions.VARTICAL_PLANNING'), value: 'VARTICAL_PLANNING' },
  { label: i18n.t('assetLabels.typeOptions.SEPTIC_TANK'), value: 'SEPTIC_TANK' },
  { label: i18n.t('assetLabels.typeOptions.PUMP_UNIT'), value: 'PUMP_UNIT' },
  { label: i18n.t('assetLabels.typeOptions.CHLORINATION_PUMP'), value: 'CHLORINATION_PUMP' },
  { label: i18n.t('assetLabels.typeOptions.REDUCING_VALVE'), value: 'REDUCING_VALVE' },
  { label: i18n.t('assetLabels.typeOptions.FLANGE_VALVE'), value: 'FLANGE_VALVE' },
  { label: i18n.t('assetLabels.typeOptions.CHLORINE_APPARATUS'), value: 'CHLORINE_APPARATUS' },
  { label: i18n.t('assetLabels.typeOptions.WATER_METER_WITH_FILTER'), value: 'WATER_METER_WITH_FILTER' },
  { label: i18n.t('assetLabels.typeOptions.FILTER_DEVICE'), value: 'FILTER_DEVICE' },
  { label: i18n.t('assetLabels.typeOptions.ELECTROMAGNETIC_DOSING_PUMP'), value: 'ELECTROMAGNETIC_DOSING_PUMP' },
  { label: i18n.t('assetLabels.typeOptions.FIRE_HYDRANT'), value: 'FIRE_HYDRANT' },
  { label: i18n.t('assetLabels.typeOptions.ELECTRICITY_UNITS'), value: 'ELECTRICITY_UNITS' },
  { label: i18n.t('assetLabels.typeOptions.DRAINAGE'), value: 'DRAINAGE' },
  { label: i18n.t('assetLabels.typeOptions.SOFTWARE_PRODUCT'), value: 'SOFTWARE_PRODUCT' },
  { label: i18n.t('assetLabels.typeOptions.VEHICLE'), value: 'VEHICLE' },
  { label: i18n.t('assetLabels.typeOptions.WELDING_MACHINE'), value: 'WELDING_MACHINE' },
  { label: i18n.t('assetLabels.typeOptions.COMMUNICATION'), value: 'COMMUNICATION' },
  { label: i18n.t('assetLabels.typeOptions.TOOLS'), value: 'TOOLS' },
  { label: i18n.t('assetLabels.typeOptions.BUILDING'), value: 'BUILDING' },
  { label: i18n.t('assetLabels.typeOptions.OFFICE_DEVICE'), value: 'OFFICE_DEVICE' },
  { label: i18n.t('assetLabels.typeOptions.SVO'), value: 'SVO' },
  { label: i18n.t('assetLabels.typeOptions.COLLECTOR'), value: 'COLLECTOR' },
  { label: i18n.t('assetLabels.typeOptions.BRIDGE'), value: 'BRIDGE' },
  { label: i18n.t('assetLabels.typeOptions.CULVERT'), value: 'CULVERT' },
  { label: i18n.t('assetLabels.typeOptions.SKO'), value: 'SKO' },
  { label: i18n.t('assetLabels.typeOptions.GROUND'), value: 'GROUND' },
  { label: i18n.t('assetLabels.typeOptions.PRECIPITATOR'), value: 'PRECIPITATOR' },
  { label: i18n.t('assetLabels.typeOptions.BIOREACTOR'), value: 'BIOREACTOR' },
  { label: i18n.t('assetLabels.typeOptions.WATER_SOURCE_WATER_METER'), value: 'WATER_SOURCE_WATER_METER' },

];

export const orderOptions = [
  { label: i18n.t('assetLabels.orderOptions.CREATED_AT'), value: 'CREATED_AT' },
  { label: i18n.t('assetLabels.orderOptions.SKU'), value: 'SKU' },
  { label: i18n.t('assetLabels.orderOptions.LAST_WORK_REPORT'), value: 'LAST_WORK_REPORT' },
  { label: i18n.t('assetLabels.orderOptions.LAST_RENOVATION'), value: 'LAST_RENOVATION' },
  { label: i18n.t('assetLabels.orderOptions.WORK_REPORT_COUNT'), value: 'WORK_REPORT_COUNT' },
];

export const ownershipOptions = [
  { label: i18n.t('assetLabels.ownershipOptions.COUNTRY'), value: 'COUNTRY' },
  { label: i18n.t('assetLabels.ownershipOptions.SMOLYAN'), value: 'SMOLYAN' },
  { label: i18n.t('assetLabels.ownershipOptions.MADAN'), value: 'MADAN' },
  { label: i18n.t('assetLabels.ownershipOptions.RUDOZEM'), value: 'RUDOZEM' },
  { label: i18n.t('assetLabels.ownershipOptions.BORINO'), value: 'BORINO' },
  { label: i18n.t('assetLabels.ownershipOptions.DEVIN'), value: 'DEVIN' },
  { label: i18n.t('assetLabels.ownershipOptions.CHEPELARE'), value: 'CHEPELARE' },
  { label: i18n.t('assetLabels.ownershipOptions.ZLATOGRAD'), value: 'ZLATOGRAD' },
  { label: i18n.t('assetLabels.ownershipOptions.NEDELINO'), value: 'NEDELINO' },
  { label: i18n.t('assetLabels.ownershipOptions.DOSPAT'), value: 'DOSPAT' },
  { label: i18n.t('assetLabels.ownershipOptions.BANITE'), value: 'BANITE' },
  { label: i18n.t('assetLabels.ownershipOptions.CORPORATE'), value: 'CORPORATE' },
];

export const stateOptions = [
  { label: i18n.t('assetLabels.stateOptions.CRITICAL'), value: 'CRITICAL' },
  { label: i18n.t('assetLabels.stateOptions.BAD'), value: 'BAD' },
  { label: i18n.t('assetLabels.stateOptions.GOOD'), value: 'GOOD' },
  { label: i18n.t('assetLabels.stateOptions.VERY_GOOD'), value: 'VERY_GOOD' },
  { label: i18n.t('assetLabels.stateOptions.OUT_OF_EXPLOITATION'), value: 'OUT_OF_EXPLOITATION' }
];
